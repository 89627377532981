import { messages } from "@/messages";

export default function correctTypedErrorMessage(error: unknown) {
  return error && typeof error === "object"
    ? "errMsg" in error && typeof error.errMsg === "string"
      ? error.errMsg
      : "message" in error && typeof error.message === "string"
      ? error.message
      : messages.somethingWentWrong
    : messages.somethingWentWrong;
}
